<template>
<div>
    <Loading v-if="$global.state.loading" />
    <template v-if="!$global.state.loading">
        <div>
            <!-- <v-toolbar flat outlined> -->
            <v-alert color="secondary" text outlined border="left">
                <v-toolbar-title>
                    <h4 class="blacko--text">
                        المعاملة : ( {{ $route.params.permitName }} )
                    </h4>
                </v-toolbar-title>
            </v-alert>
            <!-- </v-toolbar> -->
            <v-timeline dense>
                <v-timeline-item v-for="(item, index) in items" :key="index" :color="item.user != null ? 'teal' : 'blue darken-3'" fill-dot>
                    <!-- <span>Tus eu perfecto</span> -->
                    <v-card id="card" >
                        <v-toolbar :color="item.user != null ? 'teal' : 'blue darken-3'" dark tile>
                            <v-toolbar-title>
                                <v-card-title>
                                    <h3 v-if="item.sequence == 1">تقديم طلب تصريح أمني</h3>
                                    <h3 v-if="item.sequence == 2">قسم التدقيق الأمني</h3>
                                    <h3 v-if="item.sequence == 3">فوج الحماية</h3>
                                </v-card-title>
                            </v-toolbar-title>
                            <v-spacer />
                            <h4 style="color: #122940" v-if="item.user != null">
                                <div dir="ltr">{{ new Date(item.created).toISOString().split("T")[0] }},<span>{{ new Date(item.created).toLocaleString().split(",")[1]  }}</span>
                                </div>
                            </h4>
                        </v-toolbar>
                        <v-container fluid >
                            <v-alert  dense outlined border="left" :type="item.user != null ? 'success' : 'info'" prominent :color="item.user != null ? 'teal' : 'blue darken-3'" text>
                                <v-row justify="center" align="center" >
                                    <h3 v-if="item.user != null == true" style="color: #122940">
                                        <v-row>
                                            <v-container class="hidden-sm-and-down">
                                                متخذ الإجراء ||
                                                <v-chip label>
                                                    <h3>
                                                        {{ item.user.fullName }} ( {{ item.user.userName }} )
                                                    </h3>
                                                </v-chip>
                                                ||=||
                                                <v-chip label color="primary" outlined>
                                                    <h3>{{ item.user.jobTitle }}</h3>
                                                </v-chip>
                                                ||=||
                                                <v-chip label color="secondary" outlined>
                                                    <h3>{{ item.user.organization.name}}</h3>
                                                </v-chip>
                                            </v-container>
                                            <v-container class="hidden-md-and-up">
                                                متخذ الإجراء :
                                                    <h4 class="grey">
                                                        {{ item.user.fullName }} ( {{ item.user.userName }} )
                                                    </h4>
                                                    -
                                                    <h4 class="grey">{{ item.user.jobTitle }}</h4>
                                                    -
                                                    <h4 class="grey">{{ item.user.organization.name}}</h4>
                                            </v-container>
                                        </v-row>
                                    </h3>

                                    <h3 v-if="item.user != null == false" style="color: #122940">
                                        لم يتم إتخاذ اي إجراء
                                    </h3>
                                    <v-spacer />
                                    <div class="mt-12 hidden-md-and-up"/>
                                    <v-btn @click="openDetailsDialog(item)" v-if="item.user != null || item.sequence == 3" outlined class="btn" color="teal" x-large>
                                        <v-icon class="ml-2">info</v-icon>
                                        <h4>عرض التفاصيل</h4>
                                    </v-btn>
                                    <template>
                                        <v-btn v-show="(item.sequence == 1 && $store.state.user.role == 'Benefit') || (item.sequence == 2 && $store.state.user.role == 'Security')" @click="orderData(item)" v-if="item.user == null && item.sequence != 3" class="btn" color="blue darken-3" x-large dark>
                                            <v-icon class="ml-2">view_stream</v-icon>
                                            <h4>متابعة الطلب</h4>
                                        </v-btn>
                                    </template>
                                </v-row>
                            </v-alert>
                        </v-container>
                    </v-card>
                </v-timeline-item>
            </v-timeline>
        </div>
        <Dialog />
    </template>
</div>
</template>

<script>
export default {
    components: {
        Dialog: () => import("./dialog"),
    },
    data() {
        return {
            items: [],
        };
    },
    created() {
        this.getItems();
    },
    methods: {
        orderData(item) {
            console.log(item);
            this.$router.push({
                path: "/permitData/" + item.id + "/" + item.permitId
            });
            // await this.$store.commit("setOrder", item);
        },
        async getItems() {
            this.$global.state.loading = true;
            try {
                let GroupIds = [];
                if (this.$store.state.user.user.userGroups.length > 0) {
                    await this.$store.state.user.user.userGroups.forEach((element) => {
                        GroupIds.push(element.groupId);
                    });
                }
                let url = `PermitStep?PermitId=${this.$route.params.permitId}&IsDeleted=false&Sort=desc&PageIndex=1&PageSize=100`;
                for (let j = 0; j < GroupIds.length; j++) {
                    url = url + `&GroupIds=${GroupIds[j]}`;
                }
                console.log(this.$route.params);
                const res = await this.$http.get(url);
                this.items = res.data.result;
                if (this.$store.state.user.role == 'Security') {
                    this.$eventBus.$emit('seen', this.$route.params.permitId)
                }
            } catch (err) {
                console.log(err);
            } finally {
                this.$global.state.loading = false;
            }
        },
        openDetailsDialog(item) {
            item.dialogType = "add";
            this.$store.commit("setItemDetails", item);
            this.$store.commit("setDialog2");
            this.$eventBus.$emit("fill-fields");
        },
    },
};
</script>
